@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.sv-body{
  margin-bottom: 150px;
}
.sv-string-viewer {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 25px;
color: #464646;
flex: none;
order: 0;
flex-grow: 0;
}
.sv-radio {
  border: 1px solid #000000;
  padding: 0 !important;
  border-radius: 8px !important;
  margin-bottom: 15px !important;
}
.sv-radio--checked {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 !important;
  /* gradients/green */
  background: linear-gradient(96.32deg, #17a7b1 0%, #99dcdf 103.77%);
  border-radius: 8px;
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}
.sv-item__control-label > span {
  font-family: "Merriweather" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 150% !important;
  /* identical to box height, or 30px */
  color: #000000 !important;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.sv-radio__decorator {
  display: none !important;
}
.sv-radio--checked > label > span > span {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 150%;
  /* identical to box height, or 30px */
  color: #ffffff !important;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.sv-radio--checked > label {
  position: relative !important;
  padding-right: 30px;
  width: 95%;
  padding: 16px;
}
.sv-radio--checked > label:after{
    content: '';
    display: inline;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: transparent url(Assets/radio_checked.png) no-repeat top;
    background-position-y: 50%;
    background-position-x: 100%;
}
.sv-footer__complete-btn,.sv-footer__next-btn{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 15px 24px !important;
gap: 8px;
width: 94px;
left: calc(50% - 94px/2 + 111.5px);
top: 90.86%;
bottom: 2.31%;
background: linear-gradient(96.32deg, #17A7B1 0%, #99DCDF 103.77%);
/* box-shadow: 0px 6.04423px 18.1327px -6.04423px rgba(22, 34, 51, 0.12), 0px 24.1769px 48.3539px rgba(22, 34, 51, 0.16); */
border-radius: 45px !important;
text-transform: uppercase;
margin-right: 30px !important;
font-family: 'Work Sans' !important;
}
.sv-footer__prev-btn{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 24px !important;
  gap: 10px;
  /* position: absolute; */
  width: 65px;
  left: calc(50% - 119px/2 - 116.5px);
  top: 90.86%;
  bottom: 2.31%;
  border-radius: 45px !important;
  background:transparent url(Assets/arrow-left.png) no-repeat left center !important;
  padding-left:50px !important;
font-family: 'Work Sans' !important;
font-style: normal;
font-weight: 700 !important;
font-size: 16px !important;
line-height: 120%;
text-align: center !important;
letter-spacing: 0.05em;
text-transform: uppercase;
color: #003657 !important;
}
.sv-selectbase__clear-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 24px !important;
  gap: 10px;
  /* position: absolute; */
  /* width: 119px; */
  left: calc(50% - 119px/2 - 116.5px);
  top: 90.86%;
  bottom: 2.31%;
  border-radius: 45px !important;
  background: transparent !important;
  /* background:transparent url(Assets/arrow-left.png) no-repeat left center; */
  /* padding-left:50px; */
font-family: 'Work Sans' !important;
font-style: normal;
font-weight: 700 !important;
font-size: 16px !important;
line-height: 120%;
text-align: center;
letter-spacing: 0.05em;
text-transform: uppercase;
color: #003657 !important;
}
.sv-selectbase__label {
  position: relative !important;
  display: block !important;
  box-sizing: border-box !important;
  cursor: inherit !important;
  margin-left: 0 !important;
  min-height: 30px !important;
  padding: 16px;
}
.sv-question__title--answer {
  background-color: transparent !important
}
.sv-footer{
  position: fixed;
  bottom: 0;
  background-color: #fff;
  width: 100%;
}
.sv-checkbox {
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid #DADADA;
  
}
.sv-checkbox > label{
  height: auto;
  background: transparent url(Assets/uncheck.png) no-repeat right;
  padding-right: 32px;
}
.sv-checkbox--checked > label{
  /* height: 32px; */
  background: transparent url(Assets/checkbox-check.png) no-repeat right;
}
.sv-checkbox__decorator{
  display: none !important
}
.sv-checkbox__svg{
  display: none !important
}
@media (max-width: 500px) {
  .sv-action-bar {
    position: fixed !important
  }
}

